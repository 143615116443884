(function(w){
    function getLobby(path) {
        var htmlElem = document.getElementsByTagName('html')[0];
        var brandName = htmlElem.getAttribute('data-brand');
        var lobby = {
            Lobby: false,
            LobbyName: 'GENERIC'
        };

        if (path.indexOf('/') === 0) {
            path = path.substring(1);
        }

        // Prepare 2 levels of path without search params
        var levels = path.split('/', 4);
        levels.forEach(function (p) {
            return p;
        });
        var level1 = levels[0] || '',
            level2 = levels[1] || '',
            level3 = levels[2] || '',
            level4 = levels[3] || '';

        // HOME when home or fantasy controller is active
        if ((['', 'home'].includes(level1) && brandName !== 'suaposta')
            || (level1 === 'betssonfc' && level2 === 'futebol' && level4 === '')) {

            lobby.LobbyName = 'HOME';

            // Lobby is every product AND the home page but NOT including subpages of those
            if (level2 === ''
                || path === 'betssonfc/futebol/lobby') {
                lobby.Lobby = true;
            }
            return lobby;
        }

        // return HORSE_RACING as lobby name for https://www.betsson.com.br/br/corridas-de-cavalos/
        // for some reason it's picked up as GENERIC. Interestingly enough, it works for https://www.racebets.com/en/horse-racing/
        if(level2 === 'corridas-de-cavalos') {
            lobby.LobbyName = 'HORSE_RACING';
            return lobby;
        }

        // if (path === 'betssonfc/futebol/lobby/fantasy/admin_created/all/all/waiting') {
        //     lobby.Lobby = true;
        // }

        // Identify the rest of lobby names
        switch (level1) {
            case 'event':
            case 'race':
            case 'calendar':
            case 'formguide':
            case 'instantbet':
            case 'statistics':
                lobby.LobbyName = 'HORSE_RACING';
                break;
            case 'betssonfc':
                lobby.LobbyName = 'FANTASY_GAMES';
                break;
            case 'offers':
                lobby.LobbyName = 'PROMOTIONS';
                break;
            case 'cashier':
                lobby.LobbyName = getSecondLevelLobbyName(level2) || 'GENERIC';
                break;
            case 'register':
                lobby.LobbyName = getSecondLevelLobbyName(level2) || 'REGISTER';
                break;
            case 'transactions':
            case 'bets':
            case 'account':
            case 'particulars':
                lobby.LobbyName = getSecondLevelLobbyName(level2) || 'MY_ACCOUNT';
                break;
            case 'static':
                lobby.LobbyName = getSecondLevelLobbyName(level2) || 'GENERIC';
                break;
            case 'content':
                lobby.LobbyName = 'CONTENT';
                break;
            default:
                lobby.LobbyName = 'GENERIC';
        }

        return lobby;
    }

    /**
     * Identify lobby name based on second level path
     */
    function getSecondLevelLobbyName(l) {
        switch (l) {
            case 'bonuses':
                return 'BONUSES';
            case 'deposit':
                return 'DEPOSIT';
            case 'withdrawal':
                return 'WITHDRAW';
            case 'error404':
                return 'PAGE_NOT_FOUND';
            case 'terms':
                return 'TERMS_AND_CONDITIONS';
            case 'cookiepolicy':
                return 'COOKIE_POLICY';
            case 'privacy':
                return 'PRIVACY_POLICY';
            case 'responsiblegaming':
            case 'restrictions':
                return 'RESPONSIBLE_GAMING';
            case 'forgotten':
                return 'FORGOTTEN_PASSWORD';
            case 'marketing-settings':
            case 'consent':
                return 'ADMIN';
            case 'verification':
                return 'KYC_BAR';
            case 'email':
                return 'ACTIVATE_ACCOUNT';
            case 'about':
                return 'INFO';
            default:
                return null;
        }
    }
    w.getLobby = getLobby;
})(window);
